import React from 'react'
import Abhero from './Abhero/Abhero'
import Homeabout from '../components/Homeabout/Homeabout';
import Abwhy from './Abwhy/Abwhy';


import Givmoney from './Givmoney/Givmoney';
import Acheivments from './Acheivments/Acheivments';
import whyus from "../assets/images/Mask group.png"
import aboutbg from "../assets/images/about_bg.png";
import vision from "../assets/images/vision.png";
import whyusimg from "../assets/images/story.png"

import pagebanner from "../assets/images/aboutthero.png"
import Fundcarousel from './Fundcarousel/Fundcarousel';
import Faqs from './Faqs/Faqs';
import Purpose from './Purpose/Purpose';
import Cards from './Cards/Cards';

function Aboutus() {
  return (
    <div>
      <Abhero img={pagebanner} heading={'About-Us'} subheading={'Shred the bottle - About Us'}></Abhero>
      <Homeabout aboutbg={aboutbg} tagline={"About Shred the Bottle"}   whyus={whyus} heading={"Our Eco-Friendly Mission"} subheading={"Welcome to Shred the Bottle, where we provide you with an inside look at our eco-friendly mission and what powers our recycling and rewards platform. Our goal is to share our commitment to sustainability and showcase the driving force behind our efforts."} dnone={"d-none"} ></Homeabout>
      <Purpose></Purpose>
      <Abwhy whyus={whyusimg}></Abwhy>
      <Acheivments heading={"A Vision for Sustainability"} description={"Certainly! The vision for a sustainable future at Shred the Bottle is all about taking care of our planet. It's a place where we want to encourage everyone to recycle and give them rewards for it. Recycling means using things again instead of throwing them away, and we believe it's important. So, at Shred the Bottle, we want to say thank you to people who help make the Earth a better place. It's all about doing good things for our world."} signup={"d-none"}></Acheivments>
      <Cards></Cards>
      <Givmoney></Givmoney>
    
      <Fundcarousel></Fundcarousel>
      <Homeabout aboutbg={vision} tagline={""}   whyus={whyus} heading={"A Vision for Sustainability"} subheading={"Certainly! The vision for a sustainable future at Shred the Bottle is all about taking care of our planet. Its place where we want to encourage everyone to recycle and give them rewards for it. Recycling means using things again instead of throwing them away, and we believe it's important. So, at Shred the Bottle, we want to say thank you to people who help make the Earth a better place. It's all about doing good things for our world."} dnone={"d-none"}></Homeabout>
   
   <Faqs></Faqs>
      
      
    </div>
  )
}

export default Aboutus