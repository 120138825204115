import React from 'react'
import flowerlight from "../../assets/images/flowerlight.png"
import moneybox from "../../assets/images/moneybox.png"
import {Link } from 'react-router-dom';

function Givmoney() {
  return (
    <>
            <section id="givemoney" class="my-0 py-0 mx-3 mx-md-0">
            <div class="flowerlight_bg"  style={{ backgroundImage: `url(${flowerlight})` }}>
                <div class="container">
                    <div class="about-section padding-100 m-auto">
                        <div class="row">
                            <div class="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-start align-items-center">
                                <div class="about_banner">
                                    <img src={moneybox} class="w-100 " alt=""/>
                                   
                                    <div>
    
    
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div class="about_text">
                                    <h2 class="secondary_heading mb-0 text-white font-22 fw-600">Giving is more fun
                                    </h2>
                                    <h1 class="font-45 fw-700 text-start text-white mb-3">
                                        Shred the bottle corporation will be donating 5% of its Annual income as a Zakat</h1>
    
                                </div>
    
                                <div class="about_description">
                                    <p class="text-white mt-3 mb-4 pe-3 pe-md-0">
                                        Shred the Bottle is an Alberta-licensed recycling business that allows customers to
                                        claim the amount paid against for the refundable items without leaving their homes
                                        or offices. It was prompted by the idea that many people do not recycle owing to the
                                        difficulties of sorting and dropping off.
                                    </p>
    
                                </div>
                                <div className="btnn w-100 text-center"><Link to={"/fundraising"} className=" btn-primaryy fs-18 text-center fw-700 ">FundRaising</Link></div>
                            </div>
                        </div>
    
                       
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Givmoney