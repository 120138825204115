import React from 'react'
import Abhero from './Abhero/Abhero'
import aboutbg from "../assets/images/blogpage.png";
import Bloogpage from './Bloogpage/Bloogpage';
import Abbanner from './Abbanner/Abbanner';
import Bloogs from './Bloogs/Bloogs';

function Blogspage() {
  return (
    <>
     <Abhero img={aboutbg} heading={'Blog Title'} subheading={'Shred the bottle - Blog'}></Abhero>  
     <Bloogpage ></Bloogpage>
     <Bloogs heading={"Similar Blogs"} dnone={"d-none"}></Bloogs>
     <Abbanner></Abbanner>
    </>
  )
}

export default Blogspage