import React from 'react'
import Abhero from './Abhero/Abhero'
import Abmaterial from './Abmaterial/Abmaterial';
import Fundcards from './Fundcards/Fundcards';
import Givmoney from './Givmoney/Givmoney';
import fundraisingbanner from "../assets/images/fundraisingbanner.webp"
import steponeimg from "../assets/images/stepthreeimg2.webp"
import steptwoimg from "../assets/images/steptwoimg.webp"
import stepthreeimg from "../assets/images/stepthreeimg.webp"


function Fundraising() {
  return (
    <>
      <Abhero  img={fundraisingbanner} heading={'Fundraising'} subheading={'Shred the bottle - Fundraising'}></Abhero>

      <Abmaterial heading={"How It Works"} 
      bottle={steponeimg}
        subheading={"We believe anyone can make a difference, and our fundraising program is designed to make it easy for you to support important causes"}
        stepone={"Step 1"}
        titleone={" Recycle for a Cause"}
        descone={"You collect recyclables, like empty Pepsi bottles or old clothes, using Shred the Bottle. The money you earn from recycling goes directly to a charity or cause you choose."}
        tv={steptwoimg}
        steptwo={"Step 2 "}
        titletwo={"Pick a Cause"}
        desctwo={"You get to decide which charity or cause you want to help. It could be helping kids in need, protecting the environment, or any other cause that's important to you. We have a list of charities to choose from."}
        cloth={stepthreeimg}
        stepthree={"Step 3"}
        titlethree={"Make a Difference"}
        descthree={"When you recycle with us, you're not just being kind to the planet; you're also making a real impact by supporting a cause that matters"} titlefour={"Clothing Items"}
        descfour={"When you recycle with us, you're not just being kind to the planet; you're also making a real impact by supporting a cause that matters"} titlefive={"Make a Difference"}
        descfive={"When you recycle with us, you're not just being kind to the planet; you're also making a real impact by supporting a cause that matters"} titlesix={"Make a Difference"}
        descsix={"When you recycle with us, you're not just being kind to the planet; you're also making a real impact by supporting a cause that matters"}></Abmaterial>

        <Fundcards></Fundcards>
        <div className="mb-5">
        <Givmoney></Givmoney>
        
        
        </div>
       
        
    </>
  )
}

export default Fundraising