import React, { useEffect, useState } from 'react';
import fundiconb from "../../assets/images/fundiconb.svg";
import { Link } from 'react-router-dom';

function Fundcarousel() {
  const [charities, setCharities] = useState([]);

  useEffect(() => {
    fetch('https://shredbottle.iotaiy.com/api/charity/types')
      .then(response => response.json())
      .then(data => setCharities(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
      <section id="fundcardscarousel" className='mx-3 mx-md-0'>
        <div className="container padding-100">
          <div id="carouselExampleControls" className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {charities.map((charity, index) => (
                <div key={charity.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <div className="card-wrapper container-sm d-flex justify-content-around">
                    <div className="feature col me-2">
                      <div className="fundraisingcard d-flex flex-column justify-content-center text-center align-items-center px-3 px-md-5 py-4">
                        <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-0 w-200">
                          <img src={charity.image || fundiconb} className="fundraising_img w-200" alt={charity.name} />
                        </div>
                        <h1 className="font-22 mb-0 mt-3 fw-700 dark-color">{charity.name}</h1>
                        <p className="dark-color text-center fw-400 my-3 my-md-3">{charity.short_desc}</p>
                        <div className="btnn w-100 text-center">
                          <Link to={"/fundraising"} className="btn-primaryy fs-18 text-center fw-700">FundRaising</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          <div className="btnn w-100 text-center m-auto mt-2 mt-md-5">
            <Link to={"/fundraising"} className="btn-primaryy fs-18 text-center fw-700">View FundRaising</Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Fundcarousel;
