import React from 'react';

function Privacypolicy() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated: [Date]</strong></p>

      <p>Welcome to [Your Website Name]. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to ensure your information is protected.</p>

      <h2>1. Information We Collect</h2>

      <h3>a. Personal Information</h3>
      <p>When you use our services, we may collect the following personal information:</p>
      <ul>
        <li><strong>Name</strong></li>
        <li><strong>Email address</strong></li>
        <li><strong>Phone number</strong></li>
        <li><strong>Address</strong></li>
        <li><strong>Payment information</strong> (if applicable)</li>
      </ul>

      <h3>b. Driver Information</h3>
      <p>If you apply to be a driver, we may also collect:</p>
      <ul>
        <li><strong>Driver's license information</strong></li>
        <li><strong>Vehicle information</strong></li>
        <li><strong>Background check information</strong></li>
      </ul>

      <h3>c. Usage Data</h3>
      <p>We may collect information about how you use our website, such as:</p>
      <ul>
        <li><strong>IP address</strong></li>
        <li><strong>Browser type</strong></li>
        <li><strong>Pages visited</strong></li>
        <li><strong>Time spent on pages</strong></li>
        <li><strong>Referring website</strong></li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li><strong>Providing Services</strong>: To facilitate garbage collection and driver assignments.</li>
        <li><strong>Communication</strong>: To communicate with you about your orders, provide customer support, and send updates.</li>
        <li><strong>Improvement</strong>: To improve our services, website functionality, and user experience.</li>
        <li><strong>Legal Compliance</strong>: To comply with applicable laws and regulations.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell your personal information to third parties. However, we may share your information with:</p>
      <ul>
        <li><strong>Service Providers</strong>: Third-party vendors who assist us in operating our website and conducting our business.</li>
        <li><strong>Legal Requirements</strong>: Authorities if required by law or to protect our rights and safety.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>

      <h2>5. Cookies</h2>
      <p>We use cookies to enhance your experience on our website. You can control the use of cookies through your browser settings.</p>

      <h2>6. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li><strong>Access</strong>: Request a copy of the personal information we hold about you.</li>
        <li><strong>Correction</strong>: Request correction of any inaccurate information.</li>
        <li><strong>Deletion</strong>: Request deletion of your personal information, subject to certain legal obligations.</li>
        <li><strong>Objection</strong>: Object to the processing of your personal information in certain circumstances.</li>
      </ul>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
      <p>[Your Company Name]<br/>
      [Your Address]<br/>
      [Your Email Address]<br/>
      [Your Phone Number]</p>
    </div>
  );
}

export default Privacypolicy;
