import React from 'react'
import clothingitem from "../assets/images/gablenew.png"
import tetraberik from "../assets/images/pouchesnew.png"
import plasticnew from "../assets/images/plasticnew.png"
import BiMetal from "../assets/images/bimetalnew.png"
import recyclebanner from "../assets/images/recyclebanner.png"
import clothes from "../assets/images/clothes.webp"
import electronics from "../assets/images/newelectronics.png"
import {Link } from 'react-router-dom';



function Plasticbot({ bottle, heading, subheading, stepone, titleone, descone, steptwo, tv, titletwo, desctwo, stepthree, cloth, titlethree, descthree, }) {
    return (
        <>
            <section id="materials" className="my-0 py-0  mx-3 mx-md-0 ">
                <div className="inner-container m-auto">
                    <div className=" padding-100 m-auto">

                        <div className="about-section m-auto row mb-3 mb-md-5">


                            <h1 className="font-45 fw-700 dark-color">{heading}</h1>
                            <div className="materialcollectpara">

                                <p className="text-center dark-color">{subheading}</p>
                            </div>


                        </div>


                        <div className="about-section m-auto row pb-50">
                            <div className="col-12 col-md-6 mb-4 mb-md-0">
                                <div className="about_banner">
                                    <img src={bottle} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">
                                    <h1 className="font-22 dark-color"> {stepone}</h1>

                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">{titleone}</h1>


                                </div>

                                <div className="about_description">

                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                        {descone}
                                    </p>

                                </div>
                            
                                <div className="about_text max-content">

                                    <h1 className="font-22 fw-500 text-start mb-2"> Pricings</h1>

                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                        1L And Under 7¢ And Over For 20¢
                                        With no hidden fee
                                    </p>

                                </div>
                                <div class="btnn w-100 mx-2 mb-3 mb-md-0 text-center"><Link to={"/requestpickup"} class=" btn-primaryy fs-18 fw-700 ">Request A Pickup</Link></div>

                            </div>
                        </div>
                        <div className="about-section m-auto row pb-50">

                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">

                                    <h1 className="font-22 dark-color"> {steptwo}</h1>
                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">{titletwo}</h1>

                                </div>

                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                        {desctwo}
                                    </p>

                                </div>
                               
                                <div className="about_text max-content">

                                    <h1 className="font-22 fw-500 text-start mb-2"> Pricings</h1>

                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                        1L And Under 7¢ And Over For 20¢
                                        With no hidden fee
                                    </p>

                                </div>
                                  <div class="btnn w-100 mx-2 mb-3 mb-md-0 text-center"><Link to={"/requestpickup"} class=" btn-primaryy fs-18 fw-700 ">Request A Pickup</Link></div>
                            </div>

                            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                                <div className="about_banner">
                                    <img src={tv} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-section m-auto row pb-50">
                            <div className="col-12 col-md-6 mb-4 mb-md-0">
                                <div className="about_banner d-flex justify-content-center">
                                    <img src={cloth} className="w-75 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-22 dark-color"> {stepthree}</h1>
                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">{titlethree}</h1>


                                </div>

                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">{descthree}
                                    </p>

                                </div>
                              
                                <div className="about_text max-content">

                                    <h1 className="font-22 fw-500 text-start mb-2">No Pricings</h1>

                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                        1L And Under 7¢ And Over For 20¢
                                        With no hidden fee
                                    </p>

                                </div>
                                 <div class="btnn w-100 mx-2 mb-3 mb-md-0 text-center"><Link to={"/requestpickup"} class=" btn-primaryy fs-18 fw-700 ">Request A Pickup</Link></div>
                            </div>
                        </div>
                        <div className="about-section m-auto row pb-50">


                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Bi Metal</h1>

                                </div>

                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                        coconut water cans, Tomato juice

                                    </p>

                                </div>

                                <div className="about_text max-content">

                                    <h1 className="font-22 fw-500 text-start mb-2"> Pricings</h1>

                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                        1L And Under 7¢ And Over For 20¢
                                        With no hidden fee
                                    </p>

                                </div>
                                 <div class="btnn w-100 mx-2 mb-3 mb-md-0 text-center"><Link to={"/requestpickup"} class=" btn-primaryy fs-18 fw-700 ">Request A Pickup</Link></div>
                            </div>

                            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                                <div className="about_banner">
                                    <img src={BiMetal} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="about-section m-auto row pb-50">
                            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                                <div className="about_banner">
                                    <img src={tetraberik} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Pouches</h1>

                                </div>

                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                        Juice and wine pouches

                                    </p>

                                </div>

                                <div className="about_text max-content">

                                    <h1 className="font-22 fw-500 text-start mb-2"> Pricings</h1>

                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                        1L And Under 7¢ And Over For 20¢
                                        With no hidden fee
                                    </p>

                                </div>
                                 <div class="btnn w-100 mx-2 mb-3 mb-md-0 text-center"><Link to={"/requestpickup"} class=" btn-primaryy fs-18 fw-700 ">Request A Pickup</Link></div>
                            </div>


                        </div>
                        <div className="about-section m-auto row pb-50">

                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Gable Top</h1>

                                </div>

                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                        Gable top milk containers, juice containers

                                    </p>

                                </div>

                                <div className="about_text max-content">

                                    <h1 className="font-22 fw-500 text-start mb-2"> Pricings</h1>

                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                        1L And Under 7¢ And Over For 20¢
                                        With no hidden fee
                                    </p>

                                </div>
                                 <div class="btnn w-100 mx-2 mb-3 mb-md-0 text-center"><Link to={"/requestpickup"} class=" btn-primaryy fs-18 fw-700 ">Request A Pickup</Link></div>
                            </div>

                            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                                <div className="about_banner">
                                    <img src={clothingitem} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-section m-auto row pb-50">
                            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                                <div className="about_banner">
                                    <img src={plasticnew} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Clear plastic containers Polyethylene terephthalate (PET)
                                    </h1>

                                </div>

                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                        Water bottle, juice containers


                                    </p>

                                </div>

                                <div className="about_text max-content">

                                    <h1 className="font-22 fw-500 text-start mb-2"> Pricings</h1>

                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                        1L And Under 7¢ And Over For 20¢
                                        With no hidden fee
                                    </p>

                                </div>
                                 <div class="btnn w-100 mx-2 mb-3 mb-md-0 text-center"><Link to={"/requestpickup"} class=" btn-primaryy fs-18 fw-700 ">Request A Pickup</Link></div>
                            </div>


                        </div>
                        <div className="about-section m-auto row pb-50">
                            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                                <div className="about_banner">
                                    <img src={recyclebanner} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Unacceptable Items are Below
                                    </h1>

                                </div>

                                <div className="about_description my-2 my-md-3">

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Beverage containers that are not considered “ready-to-serve.”</p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Concentrate mixes

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Bleach containers and bottles
                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Windshield washer fluid
                                        Vinegar bottles
                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Jam and spread jars

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span>  Tuna and sardine cans

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Detergent bottles

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Canned veggies and soups

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Cooking oil bottles

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Pill containers

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Yogurt container
                                    </p>


                                </div>


                            </div>


                        </div>

                        <div className="row pb-50 mx-md-5">
                            <h1 className="font-45 fw-700 dark-color">Clothes</h1>
                            <div className="mb-3 mb-md-5">

                                <p className="text-center dark-color">	We provide a free pick up for all your unused clothing </p>
                            </div>
                            <div className="col-12 col-md-4 mt-0 mt-md-3  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Acceptable items:
                                    </h1>

                                </div>

                                <div className="about_description my-2 my-md-3">

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Pants, sweaters, shirts , t-shirts , underwear </p>




                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Jackets , coats

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Sneakers , runners , heels ,  dress shoes
                                    </p>

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Underwear and bras


                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Hats, scarves and other accessories

                                    </p>

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Hats, scarves and other


                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Cleaning rags, cloths, and any kind of scrap fabric as far they are laundered before



                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Bedding, towels, curtains, pillow



                                    </p>


                                </div>


                            </div>
                            <div className="col-12 col-md-4 mt-0 mt-md-3  mb-4 mb-md-0 d-flex justify-content-start align-items-start">
                                <div className="about_banner">
                                    <img src={clothes} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 mt-0 mt-md-3   d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Unacceptable Items are Below
                                    </h1>

                                </div>

                                <div className="about_description my-2 my-md-3">

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> All unwashed clothing </p>


                                </div>


                            </div>

                            <div className="about_text max-content mt-3 mt-md-5 m-auto">

                                <h1 className="font-45 fw-600 text-center mb-2"> Pricing = 0$</h1>

                            </div>


                        </div>

                        <div className="row pb-50 mx-md-5">
                            <h1 className="font-45 fw-700 dark-color">Electronics</h1>
                            <div className="mb-3 mb-md-5">

                                <p className="view_all_services m-auto text-center dark-color">We provide free pick up for all your unwanted electronics and let us recycle that for you with zero charge and no hidden fee  </p>
                            </div>
                            <div className="col-12 col-md-4 mt-0 mt-md-3  d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Acceptable items:
                                    </h1>

                                </div>

                                <div className="about_description my-2 my-md-3">

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Vaccum, Toaster, Heater, Microwave 
</p>




                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Digital cameras, Clock radio, CD and DVD players 


                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span>Satellite dish and radio, Cell phones 

                                    </p>

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Laptops computers, Desktops  computers 



                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span>Speaker phone, Routers and modems


                                    </p>

                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Landline phone, Video cameras 



                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Pager, Answering machine 



                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Skill saw, Nail gun, Shop vacuum 
                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Table saw, Drill, Sharpener 
                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span> Amplifier, Microphone 

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span>Keyboard, Guitar 
                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span>Video games any kind 

                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span>Coffee maker 
                                    </p>
                                    <p className='mb-0 pb-0'><span className='dot'>.</span>TV 
                                    </p>


                                </div>


                            </div>
                            <div className="col-12 col-md-4 mt-0 mt-md-3  mb-4 mb-md-0 d-flex justify-content-center align-items-center">
                                <div className="about_banner">
                                    <img src={electronics} className="w-100 " alt="" />

                                    <div>


                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-4 mt-0 mt-md-3   d-flex  flex-column justify-content-center">
                                <div className="about_text">


                                    <h1 className="font-45 fw-700 text-start dark-color mb-3">Note
                                    </h1>

                                </div>

                                <div className="about_description my-2 my-md-3">

                                    <p className='mb-0 pb-0'> We also pick large appliances like Washing machine, heaters , freezer, fridges , on a special request please check with our customer service for booking special appointment  </p>


                                </div>


                            </div>

                            <div className="about_text max-content mt-3 mt-md-5 m-auto">

                                <h1 className="font-45 fw-600 text-center mb-2"> Pricing = 0$</h1>

                            </div>


                        </div>









                    </div>
                </div>
            </section>
        </>
    )
}

export default Plasticbot