import React from 'react'


function Abhero({img, heading, subheading}) {

  return (
    <>
       <div id="about_hero" className='mt-2' style={{ backgroundImage: `url(${img})` }}>

            <div className="position-relative overflow-hidden">
                <div className="col-md-6 w-100">
                    <h1 className="font-45 fw-500 text-white">{heading}</h1>
                    <h3 className="font-22 fw-500 about_desc text-center">{subheading}</h3>

                </div>
                <div className="product-device shadow-sm d-none d-md-block"></div>
                <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
            </div>
        </div>
    </>
  )
}

export default Abhero