import React from 'react'

function Abwhy({whyus}) {
    return (
        <>
            <section id="whychoose" class="my-0 py-0 mx-3 mx-md-0">

                <div class="container m-auto">
                    <div class="about-section padding-100 m-auto">
                        <div class="row">

                            <div class="col-12 col-md-6 d-flex justify-content-center flex-column ">
                                <div class="about_text">
                                    
                                    <h1 class="font-45 fw-700 text-start mb-3"> Our Story</h1>

                                </div>

                                <div class="about_description">
                                    <p class="dark-color mt-3 mb-4 pe-1 pe-md-0">
                                    At Shred the Bottle, we believe that every small action counts when it comes to preserving our planet. Our journey began with a simple idea: to make recycling not just a responsible choice but a rewarding one. We're passionate about giving a new lease of life to used Pepsi bottles, clothing, and electronic devices, and here's how it all started: 
                                    </p>

                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6 order-2 order-md-1 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                                <div class="about_banner">
                                    <img src={whyus} class="w-100" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Abwhy