import React from 'react'
import Planet from "../../assets/images/Planet.svg";
import Journey from "../../assets/images/Journey.svg";
import Groups from "../../assets/images/Groups.svg";
import roadmap_bg from "../../assets/images/roadmap_bg.png";


function Roadmap() {
  return (
    <>
       <section id="roadmap" className="my-0 py-0">

<div className="container bg-transparent m-auto">
    <div className="inner-container padding-100 bg-transparent m-auto">
    <h2 className="font-45 dark-color fw-700 bg-transparent">Our Values</h2>
        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-0 px-5 px-md-0">


            <div className="col-12 col-md-5">
                <div className="row row-cols-1 row-cols-sm-1 g-5">
                    <div className="col d-flex align-items-start px-0 mx-0">
                        <div
                            className="icon-square primary-bgcolor d-inline-flex align-items-center py-2 rounded-pill justify-content-center  flex-shrink-0 me-3">
                            <img src={Planet} className="bg-transparent w-75" alt=""/>
                        </div>
                        <div>
                            <h3 className="font-22 fw-500 black-color">Initiating Change</h3>
                            <p className="black-color">Laying the Cornerstone for Our Recycling Mission</p>

                        </div>
                    </div>
                    <div className="col d-flex align-items-start px-0 mx-0">
                        <div
                            className="icon-square primary-bgcolor d-inline-flex align-items-center py-2 rounded-pill justify-content-center  flex-shrink-0 me-3">
                            <img src={Journey} className="bg-transparent w-75" alt=""/>
                        </div>
                        <div>
                            <h3 className="font-22 fw-500 black-color">Amplifying Impact</h3>
                            <p className="black-color">Advancing Technology and Community Participation to
                                Magnify</p>

                        </div>
                    </div>
                    <div className="col d-flex align-items-start px-0 mx-0">
                        <div
                            className="icon-square primary-bgcolor d-inline-flex align-items-center py-2 rounded-pill justify-content-center  flex-shrink-0 me-3">
                            <img src={Groups} className="bg-transparent w-75" alt=""/>
                        </div>
                        <div>
                            <h3 className="font-22 fw-500 black-color">United Efforts</h3>
                            <p className="black-color">Fostering Partnerships for Worldwide Plastic Bottle
                                Recycling</p>

                        </div>
                    </div>

                </div>
            </div>

            <div className="col-12 col-md-7 d-flex flex-column align-items-start gap-2">
                <img src={roadmap_bg} className="w-100 h-100 object-fit-contain" alt=""/>
            </div>
        </div>
    </div>
</div>

</section>

    </>
  )
}

export default Roadmap