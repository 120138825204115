import React from 'react'
import Abhero from './Abhero/Abhero'
import Homeabout from '../components/Homeabout/Homeabout';
import Acheivments from './Acheivments/Acheivments';
import Homeservice from '../components/Homeservice/Homeservice';
import Abbanner from './Abbanner/Abbanner';
import aboutbg from "../assets/images/condobanner.png";
import cycle from "../assets/images/condoabout.png";

function Condo() {
  return ( 
    <>
      <Abhero img={aboutbg} heading={'Condo Pickup'} subheading={'Shred the bottle - Condo Pickup'}></Abhero>   
     <Homeabout aboutbg={cycle} tagline={"About Shred the Bottle"}   heading={"Working for a Clean Tomorrow with Purpose and Progress"} subheading={"Shred the Bottle is an Alberta-licensed recycling business that allows customers to claim the amount paid against for the refundable items without leaving their homes or offices. It was prompted by the idea that many people do not recycle owing to the difficulties of sorting and dropping off."} dnone={"d-none"}></Homeabout>
     <Acheivments heading={"Residential Plastic Bottle Pickup"} subheading={""}  signup= {""}></Acheivments>
     <Homeservice heading={"What we do"} description={"Since 2016, we've been dedicated to helping families and individuals thrive through our various initiatives and programs."} dnonefooter={"d-none"} footerdesc={"We have a strong foundation built on legacy and emerging technologies, including excellent track record of on-time deliveries"} footerbtn={"Request Pickup"}></Homeservice>
     <Abbanner></Abbanner>
    </>
  )
}

export default Condo