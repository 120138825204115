import React from 'react'
import abbanner from "../../assets/images/bannerimg.png"
import {Link } from 'react-router-dom';

function Abbanner() {
  return (
    <>
       <section id="banner" className="my-0 py-0 mt-md-5" style={{ backgroundImage: `url(${abbanner})` }}>
            <div className="bannerimgcontainer padding-100">

         
            <div className="px-4 py-5 my-5 text-center">
                
                <h1 className="font-45 fw-500 text-white my-0 py-0">It's Time To Discover</h1>
                <div className="col-lg-6 mx-auto">
                  <p className="text-white my-0 text-center pt-3 pb-4 my-0">Embark on a journey of fundraising possibilities as we explore new horizons, create impact, and achieve meaningful change together.</p>
                  <div className="btnn w-100 text-center m-auto"><Link to={"/fundraising"} className=" btn-primaryy fs-18 text-center fw-700 ">FundRaising</Link></div>
                </div>
              </div>
            </div>
        </section>
    </>
  )
}

export default Abbanner