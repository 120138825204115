import React from 'react'
import pickup from "../../assets/images/pickup-02.png"
import recycle from "../../assets/images/sort_Recycle Bins Trash Sorting.png"
import moneyartboard from "../../assets/images/money_Artboard 1.png"
import artboard from "../../assets/images/process_Artboard 1.png"

function Wrkprc() {
  return (
    <>
      <section id="workingprocess" class="my-0 py-0  mx-3 mx-md-0 ">
            <div class="container m-auto">
                <div class="about-section padding-100 m-auto">

                    <div class="row mb-3 mb-md-5">
                        <h1 class="font-45 fw-700 dark-color mb-0" >4 Simple Steps
                          </h1>
                            <h1 className="font-45 fw-700 dark-color mb-4" >  Our Working Process</h1>
                       <div class="materialcollectpara"><p class="text-center dark-color">Receive payment for your recyclable bottles
                        at home within 24 - 48 hours!</p></div> 
                    </div>


                    <div class="row pb-50">
                        <div class="col-12 col-md-6 mb-4 mb-md-0">
                            <div class="about_banner">
                                <img src={pickup} class="w-100 " alt=""/>
                               
                                <div>


                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6  d-flex  flex-column justify-content-center">
                            <div class="about_text">
                              
                                <h1 class="font-45 fw-700 text-start dark-color mb-3"> Pick up/label</h1>

                            </div>

                            <div class="about_description">
                                <p class="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                    Shred the Bottle is an Alberta-licensed recycling business that allows customers to
                                    claim the amount paid against for the refundable items without leaving their homes
                                    or offices. It was prompted by the idea that many people do not recycle owing to the
                                    difficulties of sorting and dropping off.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="row pb-50">
                       
                        <div class="col-12 col-md-6  d-flex  flex-column justify-content-center">
                            <div class="about_text">
                              
                                <h1 class="font-45 fw-700 text-start dark-color mb-3">Count/Sort</h1>

                            </div>

                            <div class="about_description">
                                <p class="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                    Shred the Bottle is an Alberta-licensed recycling business that allows customers to
                                    claim the amount paid against for the refundable items without leaving their homes
                                    or offices. It was prompted by the idea that many people do not recycle owing to the
                                    difficulties of sorting and dropping off.
                                </p>

                            </div>
                        </div>

                        <div class="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center">
                            <div class="about_banner">
                                <img src={recycle} class="w-100 " alt=""/>
                               
                                <div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-50">
                        <div class="col-12 col-md-6 mb-4 mb-md-0">
                            <div class="about_banner">
                                <img src={moneyartboard} class="w-100 " alt=""/>
                               
                                <div>


                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6  d-flex  flex-column justify-content-center">
                            <div class="about_text">
                              
                                <h1 class="font-45 fw-700 text-start dark-color mb-3">Payment</h1>

                            </div>

                            <div class="about_description">
                                <p class="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                    Shred the Bottle is an Alberta-licensed recycling business that allows customers to
                                    claim the amount paid against for the refundable items without leaving their homes
                                    or offices. It was prompted by the idea that many people do not recycle owing to the
                                    difficulties of sorting and dropping off.
                                </p>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                       
                        <div class="col-12 col-md-6  d-flex  flex-column justify-content-center">
                            <div class="about_text">
                              
                                <h1 class="font-45 fw-700 text-start dark-color mb-3">Shred</h1>

                            </div>

                            <div class="about_description">
                                <p class="dark-color mt-3 mb-4 pe-3 pe-md-0">
                                    Shred the Bottle is an Alberta-licensed recycling business that allows customers to
                                    claim the amount paid against for the refundable items without leaving their homes
                                    or offices. It was prompted by the idea that many people do not recycle owing to the
                                    difficulties of sorting and dropping off.
                                </p>

                            </div>
                        </div>

                        <div class="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center">
                            <div class="about_banner">
                                <img src={artboard} class="w-100 " alt=""/>
                               
                                <div>


                                </div>
                            </div>
                        </div>
                    </div>

                  
                </div>
            </div>
        </section>
    </>
  )
}

export default Wrkprc