import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Bloogs({ heading, dnone }) {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch('https://shredbottle.iotaiy.com/api/blogs/list')
      .then(response => response.json())
      .then(data => setBlogs(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
      <section id="blogs">
        <div className="container padding-100">
          <div className="inner-container">
            <h1 className="font-45 fw-600 text-start mb-2 mb-md-4 text-center">{heading}</h1>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
              {blogs.map(blog => (
                <div className={`col`} key={blog.id}>
                  <div className="card bg-transparent border-0">
                    <img src={`${blog.image}`} className="w-100 h-100" alt={blog.name} />
                    <div className="card-body ms-0 ps-0">
                      <h1 className="dark-color fw-600 font-32 pb-2 b-green-bottom">{blog.name}</h1>
                      <p className="dark-color">{blog.short_desc}</p>
                      <div className="btnn w-100 text-center">
                        <Link className="btn-primaryy fs-18 text-center fw-700" to={`/blogspage/${blog.id}`}>Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bloogs;
