import React from 'react'
import cofounder from "../../assets/images/afilatebanner.png"
import person from "../../assets/images/person.png"
import recycle from "../../assets/images/recycle_svgrepo.com.svg"
import flowers from "../../assets/images/flowerlight.png"
import {Link } from 'react-router-dom';

const flowerStyle = {
    backgroundImage: `url(${flowers})`,  // Use backticks to interpolate the URL
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    height:'100%'
  };
function Afilate() {
  return (
    <>

<section id="" className="my-0 py-0  mx-3 mx-md-0 ">
            
            <div id="givemoney" className="container-fluid mx-0 px-0  m-auto">

                <div className="flowerlight_bg"  style={flowerStyle}>
                    <div className="about-section padding-100 m-auto">

                        <div className="row pt-md-5">
                            <div className="col-12 col-md-6 mb-4 mb-md-0 mx-2 mx-md-0">
                                <div className="about_banner">
                                    <img src={cofounder} className="w-100 " alt=""/>
                                   
                                    <div>


                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  mx-2 mx-md-0  d-flex  flex-column justify-content-center">
                                <div className="about_text">
                                    <h2 className="secondary_heading mb-0 dark-color text-white font-22 fw-600">School Fundraising

                                    </h2>
                                    <h1 className="font-45 fw-700 text-start text-white dark-color mb-3"> See Our Affiliate Schools</h1>

                                </div>

                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0 text-white">
                                        Shred the Bottle is an Alberta-licensed recycling business that allows customers
                                        to
                                        claim the amount paid against for the refundable items without leaving their
                                        homes
                                        or offices. It was prompted by the idea that many people do not recycle owing to
                                        the
                                        difficulties of sorting and dropping off.
                                    </p>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default Afilate