import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import servicehome from "../../assets/images/home.svg";
import shop from "../../assets/images/shop-2svg.svg";
import parliment from "../../assets/images/parliment.svg";

function Homeservice({ heading, description, dnone, dnonefooter, footerdesc, footerbtn }) {
    const [services, setServices] = useState([]);

    useEffect(() => {
        fetch('https://shredbottle.iotaiy.com/api/our/services')
            .then(response => response.json())
            .then(data => setServices(data.data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <section id="services" className="my-0 py-0">
            <div className="container bg-transparent m-auto">
                <div className="inner-container bg-transparent padding-100 m-auto">
                    <div className="bg-transparent" id="featured-3">
                        <h2 className="font-45 dark-color fw-700 bg-transparent">{heading}</h2>
                        <p className="dark-color text-center mx-auto text-center hero_content">{description}</p>
                        <div className="row bg-transparent g-4 py-5 row-cols-1 row-cols-lg-3">
                            {services.map(service => (
                                <div key={service.id} className="feature d-flex flex-column justify-content-center align-items-center bg-transparent col text-center mb-3 mb-md-0">
                                    <div className="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3 min-200 ">
                                        <img src={service.image} className="bg-transparen w-200" alt="" />
                                    </div>
                                    <h3 className="font-22 fw-700 dark-color text-center bg-transparent mb-0">{service.name}</h3>
                                    <p className="bg-transparent dark-color text-center px-0 px-5 py-2">{service.short_desc}</p>
                                    <div className="btnn w-100 text-center mx-2">
                                        <a className="btn-primaryy fs-18 text-center fw-700" href="https://shredbottle.iotaiy.com/customer/create/pickup-request">Request Pickup</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                     
                        <div className={`view_all_services bg-transparent d-flex flex-column align-items-center m-auto text-center pt-2 pt-md-4 ${dnonefooter}`}>
                            <p className="pb-3 mb-2 mb-sm-3 mb-lg-3 bg-transparent mx-auto text-center">{footerdesc}</p>
                            <div className="btnn w-100 text-center mx-2">
                                <Link className="btn-primaryy fs-18 text-center fw-700" to={"/services"}>{footerbtn}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Homeservice;
