import React from 'react'
import about_bg from "../../assets/images/meeting.webp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

function Whychoose() {
  return (
    <>
       <section id="whychoose" class="my-0 py-0 mx-3 mx-md-0">

<div class="container m-auto">
    <div class="about-section padding-100 m-auto">
        <div class="row">

            <div class="col-12 col-md-6  order-2 order-md-1">
                <div class="about_text pt-lg-5 ">

                    <h1 class="font-45 fw-600 text-start mb-3">Why Choose Shred the Bottle?</h1>

                </div>

                <div class="about_description">
                    <ul class="mx-0 px-0 mt-3">
                        <li>
                            <p href="">
                               
                                <span className='fa-solid fa-check primary-bgcolor text-white me-2'><FontAwesomeIcon icon={faCheck} /></span>
                                We
                                work hard to remain a preferred recycling service</p>
                        </li>
                        <li>
                            <p href="">
                            <span className='fa-solid fa-check primary-bgcolor text-white me-2'><FontAwesomeIcon icon={faCheck} /></span>
                                    Because we
                                are easy to work with</p>
                        </li>
                        <li>
                            <p href="">
                            <span className='fa-solid fa-check primary-bgcolor text-white me-2'><FontAwesomeIcon icon={faCheck} /></span>
                                    Because we
                                focus on result</p>
                        </li>
                        <li>
                            <p href="">
                            <span className='fa-solid fa-check primary-bgcolor text-white me-2'><FontAwesomeIcon icon={faCheck} /></span>
                                We
                                value our Clients</p>
                        </li>
                        <li>
                            <p href="">
                            <span className='fa-solid fa-check primary-bgcolor text-white me-2'><FontAwesomeIcon icon={faCheck} /></span>
                                We
                                know your needs</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 col-md-6 order-1 order-md-2 mb-3 mb-md-0 d-flex justify-content-center">
                <div class="about_banner">
                    <img src={about_bg} class="w-100" alt=""/>
                </div>
            </div>
        </div>
    </div>
</div>

</section>
    </>
  )
}

export default Whychoose