import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Abmaterial({ heading, subheading }) {
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    fetch('https://shredbottle.iotaiy.com/api/meterials/collects')
      .then(response => response.json())
      .then(data => setMaterials(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <>
      <section id="materials" className="my-0 py-0 mx-3 mx-md-0">
        <div className="container m-auto">
          <div className="about-section padding-100 m-auto">
            <div className="row mb-3 mb-md-5">
              <h1 className="font-45 fw-700 dark-color">{heading}</h1>
              <div className="hero_content m-auto">
                <p className="text-center dark-color">{subheading}</p>
              </div>
            </div>

            {materials.map((material, index) => (
              <div className="row pb-50" key={material.id}>
                {index % 2 === 0 ? (
                  <>
                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                      <div className="about_banner">
                        <img src={`${material.image}`} className="w-75" alt={material.name} />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                      <div className="about_text">
                        <h1 className="font-22 dark-color">Step 1</h1>
                        <h1 className="font-45 fw-700 text-start dark-color mb-3">{material.name}</h1>
                      </div>
                      <div className="about_description">
                        <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                          {material.short_desc}
                        </p>
                      </div>
                      <div className="btnn w-100 text-center">
                        <Link className="btn-primaryy fs-18 text-center fw-700" to="/pricing">Type & Pricing</Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                      <div className="about_text">
                        <h1 className="font-22 dark-color">Step 2</h1>
                        <h1 className="font-45 fw-700 text-start dark-color mb-3">{material.name}</h1>
                      </div>
                      <div className="about_description">
                        <p className="dark-color mt-3 mb-4 pe-3 pe-md-0">
                          {material.short_desc}
                        </p>
                      </div>
                      <div className="btnn w-100 text-center">
                        <Link className="btn-primaryy fs-18 text-center fw-700" to="/pricing">Type & Pricing</Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                      <div className="about_banner">
                        <img src={`${material.image}`} className="w-100" alt={material.name} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}

          </div>
        </div>
      </section>
    </>
  );
}

export default Abmaterial;
