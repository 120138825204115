import React from 'react'
import Hero from './components/Hero/Hero';
import Homeabout from './components/Homeabout/Homeabout';
import Homeservice from './components/Homeservice/Homeservice';
import Roadmap from './components/Roadmap/Roadmap';
import Matcollection from './components/Matcollection/Matcollection';
import Whychoose from './components/Whychoose/Whychoose';
import whyusimg from "./assets/images/Mask group.png"
import Whyus from './components/Whyus/Whyus';
import Abbanner from './components/Abbanner/Abbanner';
import aboutbg from "./assets/images/about_bg.png";
import Foursteps from './components/Foursteps/Foursteps';


function Home() {
  return (
 <>

 <Hero></Hero>
 <Homeabout aboutbg={aboutbg} tagline={"About Shred the Bottle"}    heading={"Where Recycling Meets Rewards"} subheading={"Welcome to Shred the Bottle, where we provide you with an inside look at our eco-friendly mission and what powers our recycling and rewards platform. Our goal is to share our commitment to sustainability and showcase the driving force behind our efforts."}></Homeabout>
 <Homeservice heading={"Our Services"} description={"Since 2016, we've been dedicated to helping families and individuals thrive through our various initiatives and programs."} dnone={"d-none"} footerdesc={"We have a strong foundation built on legacy and emerging technologies, including excellent track record of on-time deliveries"} footerbtn={"View All Services"}></Homeservice>
 <Roadmap></Roadmap>
 <Matcollection></Matcollection>

 <Whychoose></Whychoose>
 <Foursteps></Foursteps>
 <Whyus whyus={whyusimg}></Whyus>
 
 <Abbanner></Abbanner>


 </>
  )
}

export default Home