import React from 'react'
import Abhero from './Abhero/Abhero'
import fundraisingbanner from "../assets/images/service.png"
import Homeabout from '../components/Homeabout/Homeabout';
import aboutbg from "../assets/images/about_bg.png";
import Homeservice from '../components/Homeservice/Homeservice'; 
import Acheivments from './Acheivments/Acheivments'; 
import Whyus from '../components/Whyus/Whyus';
import Abbanner from '../components/Abbanner/Abbanner';

function Services() {
  return (
    <>
      <Abhero  img={fundraisingbanner} heading={'Our Services'} subheading={'Shred the bottle - Our Services'}></Abhero>
      <Homeabout aboutbg={aboutbg} tagline={"Our Services"}    heading={"Recycling, Rewards, and Community Impact"} subheading={"Welcome to Shred the Bottle, where we provide you with an inside look at our eco-friendly mission and what powers our recycling and rewards platform. Our goal is to share our commitment to sustainability and showcase the driving force behind our efforts."} dnone={"d-none"}></Homeabout>
      <Homeservice heading={"Our Services"} description={"Since 2016, we've been dedicated to helping families and individuals thrive through our various initiatives and programs."} dnone={"d-none"} dnonefooter={"d-none"} footerdesc={"We have a strong foundation built on legacy and emerging technologies, including excellent track record of on-time deliveries"} footerbtn={"Request Pickup"}></Homeservice>
      <Acheivments heading={"A Vision for Sustainability"} description={"Certainly! The vision for a sustainable future at Shred the Bottle is all about taking care of our planet. It's a place where we want to encourage everyone to recycle and give them rewards for it. Recycling means using things again instead of throwing them away, and we believe it's important. So, at Shred the Bottle, we want to say thank you to people who help make the Earth a better place. It's all about doing good things for our world."} signup={"d-none"}></Acheivments>
      <Whyus></Whyus>
      <Abbanner></Abbanner>
    </>
  ) 
}

export default Services