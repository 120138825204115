import React, { useState, useEffect } from 'react';
import person from "../../assets/images/person.png"
import recycle from "../../assets/images/recycle_svgrepo.com.svg"
import {Link } from 'react-router-dom';

function Cards() {

    const [recyclableContainers, setRecyclableContainers] = useState(null);
    const [totalUsers, setTotalUsers] = useState(null);

    useEffect(() => {
        const fetchRecyclableContainers = async () => {
            try {
                const response = await fetch('https://shredbottle.iotaiy.com/api/total/containers');
                const data = await response.json();
                console.log('API data:', data);
                if (data && data.data && data.data.length > 0 && data.data[0].total_containers) {
                    setRecyclableContainers(data.data[0].total_containers);
                } else {
                    setRecyclableContainers('No data available');
                }
            } catch (error) {
                console.error('Error fetching recyclable containers:', error);
                setRecyclableContainers('Error fetching data');
            } 
        };

        const fetchTotalUsers = async () => {
            try {
                const response = await fetch('https://shredbottle.iotaiy.com/api/total/users');
                const data = await response.json();
                console.log('Total users data:', data);
                if (data && data.data && data.data.length > 0 && data.data[0].total_users) {
                    setTotalUsers(data.data[0].total_users);
                } else {
                    setTotalUsers('No data available');
                }
            } catch (error) {
                console.error('Error fetching total users:', error);
                setTotalUsers('Error fetching data');
            }
        };

        fetchRecyclableContainers();
        fetchTotalUsers();
    }, []);

  return (
    <>
     <div className="">
     <div className="cards-container px-3 px-md-0 m-auto pt-0 padding-100">
                <div className="row  mb-5 mb-md-2">
                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                        <div className="col d-flex align-items-start px-0 mx-0 primary-bgcolor py-3 px-3">
                            <div
                                className="icon-square primary-bgcolor  d-inline-flex align-items-center py-3 px-2  justify-content-center  flex-shrink-0 me-4">
                                <img src={recycle} className="bg-transparent w-75" alt=""/>
                            </div>
                            <div>
                            <h3 className="font-45 fw-700 fw-500 dark-color text-start text-white">
                                    {recyclableContainers !== null ? recyclableContainers : 'Loading...'}
                                </h3>
                                <p className="dark-color mb-2 text-white">Recyclable Containers</p>
                                <div className="btnnn_outlinee w-100 mx-0 text-center align-items-center mb-3 mb-md-0"><Link className=" btnn-white-primaryy fs-18 fw-700 " to={"/about"}>Read About Us</Link></div>

                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="col d-flex align-items-start px-0 mx-0 primary-bgcolor py-3 px-3">
                            <div
                                className="icon-square primary-bgcolor  d-inline-flex align-items-center py-3 px-2  justify-content-center  flex-shrink-0 me-4">
                                <img src={person} className="bg-transparent w-75" alt=""/>
                            </div>
                            <div>
                            <h3 className="font-45 fw-700 fw-500 dark-color text-start text-white">
                                    {totalUsers !== null ? totalUsers : 'Loading...'}
                                </h3>
                                <p className="dark-color mb-2 text-white">Satisfied & Happy People</p>

                                <div className="btnnn_outlinee w-100 mx-0 text-center align-items-center mb-3 mb-md-0"><Link className="text-dark btnn-white-primaryy fs-18 fw-700 " to={"/services"}>View Our Services</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     </div>
    </>
  )
}

export default Cards