import React from 'react'
import aboutbg from "../assets/images/pricingbanner.png";
import Abhero from './Abhero/Abhero'
import Plasticbot from '../Plasticbot/Plasticbot';
import Foursteps from './Foursteps/Foursteps';
import Abbanner from './Abbanner/Abbanner';
import steponeimg from "../assets/images/newcan.png"
import steptwoimg from "../assets/images/cocanew.png"
import stepthreeimg from "../assets/images/winenew.png"
import Afilate from './Afilate/Afilate';

function Pricing() {
  return (
    <>
    <Abhero img={aboutbg} heading={'Pricing'} subheading={'Shred the bottle - Pricing'}></Abhero>  
    <Plasticbot  heading={"Pricing for Refundable beverages"}
      bottle={steponeimg}
        subheading={""}
       
        titleone={"Aluminum"}
        descone={"Beer, Energydrinks, soda, all Aluminum Cans"}
        tv={steptwoimg}
        
        titletwo={"Plastic"}
        desctwo={"Cola bottles, milk jugs, Drinkable Yogurt"}
        cloth={stepthreeimg}
        
        titlethree={"Glass"}
        descthree={" Juice Containers, Wine Bottles, beer bottles"}></Plasticbot>
         <Foursteps></Foursteps>
         <Afilate></Afilate>
    <Abbanner></Abbanner>
    
    
    </>
  )
}

export default Pricing