import React, { useState, useEffect } from 'react';

function Fundcards() {
    const [fundraiserData, setFundraiserData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://shredbottle.iotaiy.com/api/fundraisers/list');
                const data = await response.json();
           

                if (data && data.data && data.data.length > 0) {
                    setFundraiserData(data.data[0]); // Assuming you only want to display the first fundraiser
                } else {
                    console.error('No fundraiser data found');
                }
            } catch (error) {
                console.error('Error fetching fundraiser data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <section id="fundraise" className="my-0 py-0 mx-3 mx-md-0">
            <div className="container m-auto">
                <div className="inner-container padding-100 m-auto">
                    <div className="row mb-3 mb-md-5">
                        <h1 className="font-45 fw-700 dark-color">Fundraising</h1>
                        <div className="row g-4 py-2 row-cols-1 row-cols-lg-3 m-auto">
                            <div className="feature col">
                                {fundraiserData && (
                                    <div className="fundraisingcard d-flex flex-column justify-content-center text-center align-items-center px-3 px-md-5 py-4">
                                        <img src={fundraiserData?.profile_image} alt="Fundraiser Profile" className="fundraiser-image"/>
                                        <h1 className="font-22 mb-0 mt-3 fw-700 dark- text-center">{fundraiserData.name}</h1>
                                        <p className="dark-color text-center fw-400 my-3 my-md-3">{fundraiserData.vision_mission}</p>
                                        <div className="btnn w-100 text-center">
                                            <a className="btn-primaryy fs-18 text-center fw-700" href="/fundraising">View More</a>
                                        </div>
                                    </div>
                                )}
                                {!fundraiserData && (
                                    <div className="fundraisingcard d-flex flex-column justify-content-center text-center align-items-center px-3 px-md-5 py-4">
                                        <h1 className="font-22 mb-0 mt-3 fw-700 dark-color">Loading...</h1>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Fundcards;
