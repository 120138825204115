import React from 'react'
import Abhero from './Abhero/Abhero'
import Abmaterial from './Abmaterial/Abmaterial';
import Wrkprc from './Wrkprc/Wrkprc';
import pagebanner from "../assets/images/matbanner.webp"
import bottle from "../assets/images/refunbev (1).png"
import tv from "../assets/images/tv.webp"
import cloth from "../assets/images/wintercloth.webp"
import Faqs from './Faqs/Faqs';
import Abbanner from './Abbanner/Abbanner';

function Materials() {
  return (
    <>
       <Abhero img={pagebanner} heading={'Materials We Collect'} subheading={'Shred the bottle - Materials We Collect'}></Abhero>
       <Abmaterial bottle={bottle} heading={""}
      subheading={""}
      titleone={"Refundable Beverages"}
      descone={"Shred the Bottle is an Alberta-licensed recycling business that allows customers to  claim the amount paid against for the refundable items without leaving their homes or offices. It was prompted by the idea that many people do not recycle owing to the difficulties of sorting and dropping off"} tv={tv}  titletwo={"Electronics"}
      desctwo={"Shred the Bottle is an Alberta-licensed recycling business that allows customers to  claim the amount paid against for the refundable items without leaving their homes or offices. It was prompted by the idea that many people do not recycle owing to the difficulties of sorting and dropping off"}  cloth={cloth}  titlethree={"Clothes"}
      descthree={"Shred the Bottle is an Alberta-licensed recycling business that allows customers to  claim the amount paid against for the refundable items without leaving their homes or offices. It was prompted by the idea that many people do not recycle owing to the difficulties of sorting and dropping off"}></Abmaterial>
       <Wrkprc></Wrkprc>
       <Faqs></Faqs>
       <Abbanner></Abbanner>
    </>
  )
}

export default Materials