import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import aboutbg from "../../assets/images/blogpage.png";

function Bloogpage() {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blog, setBlog] = useState(null); // State to store blog data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error state

  useEffect(() => {
    // Fetch all blog data
    fetch('https://shredbottle.iotaiy.com/api/blogs/list')
      .then(response => response.json())
      .then(data => {
        // Find the blog with the matching ID
        const foundBlog = data.data.find(blog => blog.id === parseInt(id, 10));
        if (foundBlog) {
          setBlog(foundBlog);
        } else {
          setError('Blog not found');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog data:', error);
        setError('Error fetching blog data');
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <p>Loading...</p>; // Show loading text or spinner while data is being fetched
  }

  if (error) {
    return <p>{error}</p>; // Show error message if there's an error
  }

  return (
    <>
      <section id="blogs">
        <div className="container padding-100">
          <div className="card bg-transparent border-0">
            <div className="card-body ms-0 ps-0">
              <h1 className="dark-color fw-700 font-45 pb-2 b-green-bottom text-start">{blog.name}</h1>
              <p className="dark-color" dangerouslySetInnerHTML={{ __html: blog.long_desc }}></p>
              <img src={`${blog.image}`} className="w-100 h-100 my-3 my-md-5" alt={blog.name} />
              <p className="dark-color" dangerouslySetInnerHTML={{ __html: blog.long_desc }}></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Bloogpage;
