import React from 'react'
import dustbin from "../../assets/images/dustbin.svg"
import collect from "../../assets/images/collect.svg"
import prize from "../../assets/images/Prize.svg"
import shake from "../../assets/images/shake.svg"
import {Link } from 'react-router-dom';

function Acheivments({heading, description, signup}) {
  return (
    <>
     <section id="acheivements" className="my-0 py-0  mx-3 mx-md-0 ">
            <div className="container m-auto">
                <div className="inner-container padding-100 m-auto">

                    <div className="row mb-3 mb-md-5">
                        <h1 className="font-45 fw-700 dark-color">{heading}</h1>
                        <p className='dark-color w-80 m-auto text-center'>{description}</p>

                        <div className="row g-4 py-2 row-cols-1 row-cols-lg-2">
                            <div className="col d-flex align-items-start">
                              <div className="icon-acheivement  d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 px-2 py-3 me-3 border-1">
                               <img src={dustbin} className="acheivements img w-75 h-75 object-fit-contain" alt=""/>
                              </div>
                              <div>
                                <h1 className="font-22 fw-700 dark-color">Recycling Made Rewarding</h1>
                                <p className="dark-color fw-400 me-2 me-md-5">We didn't want to be just another recycling website. Shred the Bottle is your go-to destination for a greener, cleaner, and more sustainable future. Here's what sets us apart: </p>
                               
                              </div>
                            </div>
                            <div className="col d-flex align-items-start">
                              <div className="icon-acheivement  d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 px-2 py-3 me-3 border-1">
                               <img src={collect} className="acheivements img w-75 h-75 object-fit-contain" alt=""/>
                              </div>
                              <div>
                                <h1 className="font-22 fw-700 dark-color">Collect and Recycle</h1>
                                <p className="dark-color fw-400 me-2 me-md-5">We make recycling easy for you. We collect your used Pepsi bottles, clothing, and electronic devices, so you don't have to worry about the logistics. Our team is dedicated to ensuring a smooth and efficient process </p>
                               
                              </div>
                            </div>
                            <div className="col d-flex align-items-start">
                              <div className="icon-acheivement  d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 px-2 py-3 me-3 border-1">
                               <img src={prize} className="acheivements img w-75 h-75 object-fit-contain" alt=""/>
                              </div>
                              <div>
                                <h1 className="font-22 fw-700 dark-color">Get Rewarded</h1>
                                <p className="dark-color fw-400 me-2 me-md-5">When you choose to recycle with us, you're in control. You can choose to receive money for your recyclables, putting some extra cash in your pocket. Alternatively, you can make a difference by supporting charitable organizations. The choice is yours, and it's all about empowering you to make a positive impact</p>
                               
                              </div>
                            </div>
                            <div className="col d-flex align-items-start">
                              <div className="icon-acheivement  d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 px-2 py-3 me-3 border-1">
                               <img src={shake} className="acheivements img w-75 h-75 object-fit-contain" alt=""/>
                              </div>
                              <div>
                                <h1 className="font-22 fw-700 dark-color">Pay It Forward: Support Charity</h1>
                                <p className="dark-color fw-400 me-2 me-md-5">At Shred the Bottle, we understand the importance of giving back. Our platform supports various charity organizations, offering you the option to direct your proceeds to causes that matter to you, whether it's helping orphans or supporting struggling families. </p>
                               
                              </div>
                            </div>
                           
                            
                          </div>

                          <div className={`btnn w-100 text-center m-auto ${signup}`}><a href='https://shredbottle.iotaiy.com/login' className=" btn-primaryy fs-18 text-center fw-700 ">Sign Up</a></div>

                    </div>





                </div>
            </div>
        </section>
    </>
  )
}

export default Acheivments