
import React from 'react'
import whyus from "../../assets/images/whydid.png"
import flowers from "../../assets/images/flowers.png"

const flowerStyle = {
    backgroundImage: `url(${flowers})`,  // Use backticks to interpolate the URL
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    height:'100%'
  };
function Purpose() {
    return (
        <>
               <section id="purpose" className=" m-0 p-0">
            <div className="container-fluid mx-0 px-0">
                <div className="row">
                    <div className="col-12 col-md-6 mx-0 px-0">
                        <img src={whyus} className="w-100" alt=""/>
                    </div>
                    <div className="col-12 col-md-6 mx-0 px-0">
                        <div className="flowers_bg" style={flowerStyle}>
                            <div className="container h-100 d-flex justify-content-center">
                                <div className="purpose_content padding-100 m-auto">
            
                                    <div className="d-flex flex-column">
            
                                        <div className="row">
                                            <div className="col-12 ps-0 ms-0">
                                                <h2 className="secondary_heading mb-0 dark-color font-22 fw-600">What Drives Us?
                                                   
                                                </h2>
                                                <h1 className="font-45 fw-700 dark-color text-start"> Why Did We Start?</h1>
                                                <p className="dark-color text-start purpose_desc">At Shred the Bottle we stand as a beacon of commitment to a sustainable future. Choosing us means aligning your efforts with an organization dedicated to driving real change. Our prowess in plastic bottle recycling extends beyond mere disposal; it's a transformative journey that converts waste into valuable resources. With state-of-the-art technology and eco-conscious practices, we turn plastic bottles into renewed possibilities.

                                                  </p>
                                                <p className="dark-color text-start purpose_desc">  In addition, our clothing collection initiative reflects our deep-rooted ethos of community support. When you choose us, you choose to clothe lives with dignity. Your preloved clothes become part of a greater narrative where giving amplifies impact. Our seamless processes ensure that your contributions are effortlessly integrated into positive change, fostering a cycle of environmental responsibility and social betterment. By selecting [Your Company Name], you're embracing a holistic approach to sustainability. Every plastic bottle recycled and each donated garment symbolize your commitment to a cleaner planet and a more compassionate society. Join hands with us as we pave the way for a greener, brighter tomorrow.

                                                  </p>
                                            </div>
                                            <div className="col-12 col-md-6 d-none d-none"></div>
                                        </div>
            
            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           


        </section>
        </>
    )
}

export default Purpose