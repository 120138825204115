import React from 'react'
import electonics from "../../assets/images/electronicss.png"
import clothes from "../../assets/images/clothes.png"
import refundable from "../../assets/images/bottle.png"
import {Link } from 'react-router-dom';

function Matcollection() {
  return (
    <>
      <section id="materialswecollect" className="my-0 py-0">

<div className=" bg-transparent m-auto">
    <div className="inner-container padding-100 bg-transparent m-auto">
        <div className="bg-transparent" id="featured-3">
            <h2 className="font-45 dark-color fw-700 bg-transparent">Materials We Collect & Recycle</h2>
            <div className="row bg-transparent g-4 pt-3 pt-md-5 row-cols-1 row-cols-lg-3">
            <div
                    className="feature d-flex flex-column justify-content-center align-items-center bg-transparent col text-center mb-3 mb-md-0">
                    <div
                        className="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                        <img src={refundable} className="bg-transparent w-75 mb-md-2" alt=""/>
                    </div>
                    <h3 className="font-22 fw-700 dark-color text-center bg-transparent mb-0 mt-md-0">Refundable Beverages</h3>
                    <p className="bg-transparent dark-color text-center px-0 px-5 py-2">Receive payment for your
                        recyclable bottles at home within 24 - 48 hours!</p>
                        <div className="btnn w-100 text-center mx-2 "><Link
                                className=" btn-primaryy fs-18 text-center fw-700 " to={"/materials"}>See More </Link>
                        </div>
                </div>
                <div
                    className="feature d-flex flex-column justify-content-center align-items-center bg-transparent col text-center mb-3 mb-md-0">
                    <div
                        className="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                        <img src={electonics} className="bg-transparent w-75 mb-md-2" alt=""/>
                    </div>
                    <h3 className="font-22 fw-700 dark-color text-center bg-transparent mb-0">Electronics</h3>
                    <p className="bg-transparent dark-color text-center px-0 px-5 py-2">Receive payment for your
                        recyclable bottles at home within 24 - 48 hours!</p>
                        <div className="btnn w-100 text-center mx-2 "><Link
                                className=" btn-primaryy fs-18 text-center fw-700 " to={"/materials"}>See More </Link>
                        </div>
                </div>
                
                <div
                    className="feature d-flex flex-column justify-content-center align-items-center bg-transparent col text-center mb-3 mb-md-0">
                    <div
                        className="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                        <img src={clothes} className="bg-transparent w-80 mb-md-2" alt=""/>
                    </div>
                    <h3 className="font-22 fw-700 dark-color text-center bg-transparent mb-0">Cloths</h3>
                    <p className="bg-transparent dark-color text-center px-0 px-5 py-2">Receive payment for your
                        recyclable bottles at home within 24 - 48 hours!</p>
                        <div className="btnn w-100 text-center mx-2 "><Link
                                className=" btn-primaryy fs-18 text-center fw-700 " to={"/materials"}>See More </Link>
                        </div>
                </div>
               

            </div>


        </div>
    </div>
</div>

</section>
    </>
  )
}

export default Matcollection