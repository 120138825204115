import React from 'react'
import stepone from "../../assets/images/stepone.png";
import steptwo from "../../assets/images/steptwo.png";
import stepthree from "../../assets/images/stepthree.png";
import stepfour from "../../assets/images/stepfour.png";

function Foursteps() {
  return (
    <>
     <section id="steps" class="my-0 py-0">

<div class="inner-container bg-transparent m-auto padding-100">
    <div class="bg-transparent m-auto">
        <div class="bg-transparent">
            <h2 class="font-45 dark-color fw-700 bg-transparent">4 Simple Steps</h2>
            <h2 class="font-45 dark-color fw-700 bg-transparent">Our Working Process</h2>
            <div class="row bg-transparent g-2 g-md-4 py-0 row-cols-1 row-cols-lg-4">
                <div class=" bg-transparent col text-center px-2 px-md-4">

                    <div class="py-4">
                        <div
                            class="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-0 mb-md-3">
                            <img src={stepone} class="bg-transparent w-100" alt=""/>
                        </div>
                        <h3 class="font-22 fw-700 dark-color text-center bg-transparent mb-2">Pick up/label
                        </h3>
                        <p class="dark-color text-center  mx-5 mx-md-0">Receive payment for your recyclable bottles at home within 24 - 48 hours!</p>

                    </div>

                </div>
                <div class=" bg-transparent col text-center px-2 px-md-4">
                    <div class=" py-4">
                        <div
                            class="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-0 mb-md-3">
                            <img src={steptwo} class="bg-transparent w-100" alt=""/>
                        </div>
                        <h3 class="font-22 fw-700 dark-color text-center bg-transparent mb-2">Count/Sort
                        </h3>
                        <p class="dark-color text-center  mx-5 mx-md-0">Receive payment for your recyclable bottles at home within 24 - 48 hours!</p>
                    </div>



                </div>
                <div class="feature bg-transparent col text-center px-2 px-md-4">
                    <div class="py-4">
                        <div
                            class="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-0 mb-md-3">
                            <img src={stepthree} class="bg-transparent w-100" alt=""/>
                        </div>
                        <h3 class="font-22 fw-700 dark-color text-center bg-transparent mb-2">Payment</h3>
                        <p class="dark-color text-center  mx-5 mx-md-0">Receive payment for your recyclable bottles at home within 24 - 48 hours!</p>
                    </div>


                </div>
                <div class=" bg-transparent col text-center px-2 px-md-4">
                    <div class=" py-4">
                        <div
                            class="feature-icon bg-transparent d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-0 mb-md-3">
                            <img src={stepfour} class="bg-transparent w-100" alt=""/>
                        </div>
                        <h3 class="font-22 fw-700 dark-color text-center bg-transparent mb-2">Shred</h3>
                        <p class="dark-color text-center mx-5 mx-md-0">Receive payment for your recyclable bottles at home within 24 - 48 hours!</p>
                    </div>


                </div>

            </div>


        </div>
    </div>
</div>

</section>
    </>
  )
}

export default Foursteps