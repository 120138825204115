import React from 'react'
import formsvg from "../assets/images/form.svg"

function Signup() {
  return (
    <>
        <section id="signup">
            <div className="container padding-100">
                <div className="inner-container col-xl-12 col-xxl-8 ">
                    <div className="row align-items-center ">
                        <div className="col-lg-7 text-center text-lg-start">
                            <img src={formsvg} className="w-100 h-100" alt=""/>
                        </div>
                        <div className="col-md-10 mx-auto col-lg-5">
                            <h1 className="font-45 fw-700 text-start mb-3 text-center">Sign Up</h1>
                            <form className="py-2 py-md-2  rounded-3  ">
                                <div className="d-flex flex-row">
                                    <div className="form-floating mb-3 w-50 me-1">
                                        <input type="text" className="form-control bg-transparent b-green"
                                            id="floatingInput" placeholder="name@example.com"/>
                                        <label for="floatingInput" className="primary-color">Name</label>
                                    </div>
                                    <div className="form-floating mb-3 w-50 ms-1">
                                        <input type="text" className="form-control bg-transparent b-green"
                                            id="floatingInput" placeholder="name@example.com"/>
                                        <label for="floatingInput" className="primary-color">Phone</label>
                                    </div>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control bg-transparent b-green" id="floatingInput"
                                        placeholder="name@example.com"/>
                                    <label for="floatingInput" className="primary-color">Email address</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="password" className="form-control bg-transparent b-green"
                                        id="floatingPassword" placeholder="Password"/>
                                    <label for="floatingPassword" className="primary-color">Password</label>
                                </div>
                                <div className="checkbox mb-3 mb-md-5 primary-color">
                                    <label>
                                        <input type="checkbox" className="primary-color" value="remember-me"/> Remember me
                                    </label>
                                </div>
                                <div className="d-flex justify-content-center m-auto">
                                    <div className="btnn w-100 text-center "><a
                                            className=" btn-primaryy fs-18 text-center fw-700 " href="#">Sign Up</a></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    </>
  )
}

export default Signup