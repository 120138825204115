import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import headerlogo from "../../assets/images/logo.svg";
import callchat from "../../assets/images/call-chat_svgrepo.com.svg";

function Navbar() {
    const location = useLocation();

    const isNavLinkActive = (path) => {
        return location.pathname === path;
    };

    const renderNavLink = (to, label, subLinks = []) => {
        const hasSubLinks = subLinks.length > 0;

        return (
            <li className={`nav-item ${hasSubLinks ? 'ps-lg-2 pe-lg-0 dropdown' : 'px-lg-2'}`} key={to}>
                {hasSubLinks ? (
                    <>
                        <Link className="nav-link px-0" to={to}>
                            {label}
                        </Link>
                        <Link className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <span className='fa-solid fa-check dark-color ms-0 ps-0'>
                                <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                        </Link>
                        {/* Nested Dropdown Menu */}
                        <ul className="dropdown-menu">
                            {subLinks.map((subLink, index) => (
                                <li key={index}>
                                    <Link to={subLink.to} className={`dropdown-item ${isNavLinkActive(subLink.to) ? 'active' : ''}`}>
                                        {subLink.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <Link to={to} className={`nav-link ${isNavLinkActive(to) ? 'active' : ''}`}>
                        {label}
                    </Link>
                )}
            </li>
        );
    }; 
    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg bg-transparent px-2 py-3 mt-0 ">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={"/"}>
                        <img className="nav_logo" src={headerlogo} alt="" />
                    </Link>
                    <button className="navbar-toggler mt-3 px-2" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="priamry-color navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mt-4 mt-lg-0 mb-2 mb-lg-0">
                    {renderNavLink("/", "Home")}
                    {renderNavLink("/about", "About Us")}
                    {renderNavLink("/services", "Services", [
                        { to: "/residential", label: "Residential Pickup" },
                        { to: "/commercial", label: "Commercial Pickup" },
                        { to: "/condo", label: "Condo Pickup" },
                    ])}
                    {renderNavLink("/materials", "Materials We Collect")}
                    {renderNavLink("/fundraising", "Fundraising")}
                    {renderNavLink("/location", "Location", [
                        { to: "/calgary", label: "Calgary" },
                        { to: "/regina", label: "Regina Saskatchewan" }
                    ])}
                </ul>
                        <form className="d-flex" role="search">

                            <div className="">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex align-items-center">
                                            <div className="icon">
                                                <img className="navbaricon" src={callchat} alt="" />
                                            </div>
                                            <div className="content ms-3">
                                                <p className="navbar_subheading pb-1 mb-0">Have A Pickup?</p>
                                                <p className="navbar_heading">+1(825) 712-5006</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar

