import React from 'react'
import copyright from "../../assets/images/copyright.svg"

function Copyright() {
  return (
    <>
       <div class="copyright">
            <p class="text-center  d-flex justify-content-center py-3 m-auto align-items-center primary-color"><img
                    src={copyright} class="w-30 me-2" alt=""/>2023 | Shred The Bootle Cooperation</p>
        </div>
    </>
  )
}

export default Copyright