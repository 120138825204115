import React, { useState } from "react";
import logolight from "../../assets/images/logolight.svg";
import phone from "../../assets/images/phone.svg";
import mail_svgrepo from "../../assets/images/mail_svgrepo.com.svg";
import facebook from "../../assets/images/facebook_svgrepo.com.svg";
import twitter from "../../assets/images/twitter_svgrepo.com.svg";
import instagram from "../../assets/images/instagram_svgrepo.com.svg";
import { Link } from "react-router-dom";

function Footer() {
  const [email, setEmail] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://shredbottle.iotaiy.com/api/newsletter/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        if (data && data.message === "Subscription successful") {
          setApiResponse(data.message);
          setAlertMessage("Subscription successful");
        } else {
          throw new Error("Invalid response format or message");
        }
      } else {
        const text = await response.text();
        throw new Error(
          `Response is not in JSON format. Text response: ${text}`
        );
      }
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      setAlertMessage("You are already subscribed");
    }
  };

  const handleAlertClose = () => {
    setAlertMessage("");
  };

  return (
    <section id="footer" className="py-0 my-0 ">
      <div className="container padding-100">
        <div className="row g-4">
          <div className="col-12 col-md-5">
            <div className=" offset-md-1 mb-0 ms-2 ms-md-0 ps-0 ">
              <img src={logolight} className="mb-0 footer_logo" alt="" />
              <p className="dark-color my-4 pe-5 text-white">
                Be saying moveth spirit fruitful called set evening fly give
                shall moveth lesser very. Be saying moveth spirit fruitful
                called set evening fly give shall moveth lesser very. Be saying
                moveth spirit fruitful called set evening fly give shall moveth
                lesser very.
              </p>
              <div className="">
                <ul className="ms-0 ps-0">
                  <li className="mb-4 text-white">
                    <a>
                      <img className="w-30 me-2" src={phone} alt="" />
                      XXX XXXX XXXX
                    </a>
                  </li>
                  <li className=" text-white">
                    <a>
                      <img className="w-30 me-2" src={mail_svgrepo} alt="" />
                      XXX XXXX XXXX
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-5 pe-lg-5">
            <div className="row ms-2 ms-md-0">
              <h5 className="font-22 fw-500 py-2 ps-0 ms-0 text-white footer_text">
                Quick Links
              </h5>
              <div className="col-6 col-md-4 mb-3">
                <ul className="nav flex-column">
                  <li className="nav-item mt-3 mb-4">
                    <Link to={"/home"} className="nav-link p-0 text-white">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item mb-4">
                    <Link to={"/about"} className="nav-link p-0 text-white">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item mb-4">
                    <Link to={"/services"} className="nav-link p-0 text-white">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item mb-4">
                    <a href="#" className="nav-link p-0 text-white">
                      Our Team
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-4 mb-3">
                <ul className="nav flex-column">
                  <li className="nav-item mt-3 mb-4">
                    <Link to={"/pricing"} className="nav-link p-0 text-white">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item mb-4">
                    <a href="https://shredbottle.iotaiy.com/register" className="nav-link p-0 text-white">
                      Sign Up
                    </a>
                  </li>
                  <li className="nav-item mb-4">
                    <a href="https://shredbottle.iotaiy.com/login" className="nav-link p-0 text-white">
                      Login
                    </a>
                  </li>
                  <li className="nav-item mb-4">
                    <Link to={"/blogs"} className="nav-link p-0 text-white">
                      Our Blog
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-4 mb-3 ps-lg-4">
                <ul className="nav flex-column">
                  <li className="nav-item mt-3 mb-4">
                    <a className="nav-link p-0 text-white">Contacts</a>
                  </li>
                  <li className="nav-item mb-4">
                    <Link to={"/privacypolicy"} className="nav-link p-0 text-white">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item mb-4">
                    <a href="#" className="nav-link p-0 text-white">
                      Terms Of Use
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <form onSubmit={handleSubmit}>
                  <h5 className="text-white">Subscribe to our newsletter</h5>
                  <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                    <label htmlFor="newsletter1" className="visually-hidden">
                      Email address
                    </label>
                    <input
                      id="newsletter1"
                      type="text"
                      className="form-control my-2 my-md-0"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn-primaryy fs-18 text-center fw-700"
                    >
                      Send
                    </button>
                  </div>
                </form>
                {alertMessage && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>{alertMessage}</strong>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={handleAlertClose}
                    ></button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-2 pe-lg-5">
            <div className="row">
              <h5 className="font-22 fw-500 text-white footer_text py-2 ps-0 ps-0">
                Socials
              </h5>
              <div className="col-6 col-md-4 mb-3">
                <ul className="nav flex-column">
                  <li className="nav-item mt-3 mb-4">
                    <a
                      href="#"
                      className="nav-link p-0 text-white d-inline-flex align-items-center"
                    >
                      <img className="me-2 w-30" src={facebook} alt="" />
                      Facebook
                    </a>
                  </li>
                  <li className="nav-item mb-4">
                    <a
                      href="#"
                      className="nav-link p-0 text-white d-inline-flex align-items-center"
                    >
                      <img className="me-2 w-30" src={twitter} alt="" />
                      Twitter
                    </a>
                  </li>
                  <li className="nav-item mb-4">
                    <a
                      href="#"
                      className="nav-link p-0 text-white d-inline-flex align-items-center"
                    >
                      <img className="me-2 w-30" src={instagram} alt="" />
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
