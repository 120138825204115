import React from 'react'
import {Link } from 'react-router-dom';



function Homeabout({aboutbg, tagline, heading, subheading, dnone}) {
  return (
    <section id="about" className="my-0 py-0 mx-3 mx-md-0">

    <div className="container m-auto">
        <div className="about-section m-auto padding-100">
            <div className="row">
                <div className="col-12 col-md-6 mb-4  mb-md-0">
                    <div className="about_banner">
                        <img src={aboutbg} className="w-100" alt=""/>
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-center flex-column">
                    <div className="about_text">
                        <h2 className="secondary_heading mb-0 font-22 fw-600">{tagline}</h2>
                        <h1 className="font-45 fw-700 text-start mb-3"> {heading}</h1>

                    </div>

                    <div className="about_description">
                        <p className="dark-color mt-3 mb-4 pe-1 pe-md-0">
                            {subheading}
                        </p>
                       
                    </div>
                    <div className={`btnn w-100 text-center ${dnone}`}><Link to={"/about"} className=" btn-primaryy fs-18 text-center fw-700 ">Read More</Link></div>
                </div>
            </div>
        </div>
    </div>

</section>
  )
}

export default Homeabout