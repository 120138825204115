
import './App.css';
import {Route, Routes } from 'react-router-dom';
import Home from './Home';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import Aboutus from './components/Aboutus';
import Services from './components/Services';
import Residential from './components/Residential';
import Commercial from './components/Commercial';
import Condo from './components/Condo';
import Materials from './components/Materials';
import Fundraising from './components/Fundraising';
import Signup from './components/Signup';
import Requestpickup from './components/Requestpickup';
import Blogs from './components/Blogs';
import Blogspage from './components/Blogspage';
import Login from './components/Login';
import Pricing from './components/Pricing';
import Copyright from './components/Copyright/Copyright';
import Privacy from './components/Privacypolicy/Privacypolicy';
import ScrollToTop from './components/ScrollToTop';




function App() {
  return (
   <> 
   <ScrollToTop></ScrollToTop>
    <div className="App container-fluid p-0 m-0">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/residential" element={<Residential />} />
        <Route path="/commercial" element={<Commercial />} />
        <Route path="/condo" element={<Condo />} />
        <Route path="/materials" element={<Materials />} />
        <Route path="/fundraising" element={<Fundraising />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/requestpickup" element={<Requestpickup />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogspage" element={<Blogspage />} />
        <Route path="/blogspage/:id" element={<Blogspage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacypolicy" element={<Privacy />} />
      </Routes>
      <Footer />
      <Copyright />
    </div>

    </>
  );
}

export default App;
