import React from 'react'

function Faqs() {
    return (
        <>
            <section id="faqs">
                <div className="container padding-100 ">
                    <h1 className="font-45 dark-color fw-600">4 Simple Steps</h1>
                    <h1 className="font-45 dark-color fw-600 mb-3 mb-md-5">Our Working Process</h1>
                    <div className="accordion faqs_container m-auto" id="faqAccordion" style={{
                        textAlign: "center",
                        background: "#f1fffa",
                        color: "#2b2b2b",
                    }}>
                        <div className="accordion-item border-0 mb-2 mb-md-3">
                            <h2 className="accordion-header" id="faqHeading1">
                                <h1
                                    className="accordion-button mb-0 font-22 dark-color fw-500"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faqCollapse1"
                                    style={{
                                        textAlign: "center",
                                        background: "#f1fffa",
                                        color: "#2b2b2b",
                                    }}
                                >
                                    How We Collect Clothes
                                </h1>
                            </h2>
                            <div
                                id="faqCollapse1"
                                className="accordion-collapse collapse"
                                data-bs-parent="#faqAccordion"
                                style={{
                                    backgroundColor: "#f1fffa",
                                    color: "#2b2b2b",
                                }}
                            >
                                <div
                                    className="accordion-body"
                                    style={{
                                        backgroundColor: "#f1fffa",
                                        color: "#2b2b2b",
                                    }}
                                >
                                    <p className='text-center fw-400 dark-color'> React components are modular building blocks that encapsulate UI and logic.</p>

                                </div>
                            </div>
                        </div>
                       
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqs