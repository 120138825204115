import React from 'react'
import Abhero from './Abhero/Abhero'
import aboutbg from "../assets/images/blog.png";
import Bloogs from './Bloogs/Bloogs';
import Abbanner from './Abbanner/Abbanner';

function Blogs() {
  return (
    <>
    <Abhero img={aboutbg} heading={'Blog'} subheading={'Shred the bottle - Blog'}></Abhero>  
    <Bloogs heading={"Blogs"}></Bloogs>
    <Abbanner></Abbanner>
    </>
  )
}

export default Blogs