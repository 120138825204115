import React, { useState, useEffect } from 'react';
import cofounder from "../../assets/images/cofounder.svg";
import person from "../../assets/images/person.png";
import recycle from "../../assets/images/recycle_svgrepo.com.svg";
import flowers from "../../assets/images/flowerlight.png";
import { Link } from 'react-router-dom';

const flowerStyle = {
    backgroundImage: `url(${flowers})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    height: '100%'
};

function Whyus() {
    const [recyclableContainers, setRecyclableContainers] = useState(null);
    const [totalUsers, setTotalUsers] = useState(null);

    useEffect(() => {
        const fetchRecyclableContainers = async () => {
            try {
                const response = await fetch('https://shredbottle.iotaiy.com/api/total/containers');
                const data = await response.json();
                console.log('API data:', data);
                if (data && data.data && data.data.length > 0 && data.data[0].total_containers) {
                    setRecyclableContainers(data.data[0].total_containers);
                } else {
                    setRecyclableContainers('No data available');
                }
            } catch (error) {
                console.error('Error fetching recyclable containers:', error);
                setRecyclableContainers('Error fetching data');
            } 
        };

        const fetchTotalUsers = async () => {
            try {
                const response = await fetch('https://shredbottle.iotaiy.com/api/total/users');
                const data = await response.json();
                console.log('Total users data:', data);
                if (data && data.data && data.data.length > 0 && data.data[0].total_users) {
                    setTotalUsers(data.data[0].total_users);
                } else {
                    setTotalUsers('No data available');
                }
            } catch (error) {
                console.error('Error fetching total users:', error);
                setTotalUsers('Error fetching data');
            }
        };

        fetchRecyclableContainers();
        fetchTotalUsers();
    }, []);


    return (

        <section id="" className="my-0 py-0 mx-3 mx-md-0">
            <div className="cards-container m-auto">
                <div className="row card_topcards mb-5 mb-md-2">
                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                        <div className="col d-flex align-items-start px-0 mx-0 primary-bgcolor py-3 px-3">
                            <div className="icon-square primary-bgcolor d-inline-flex align-items-center py-3 px-2 justify-content-center flex-shrink-0 me-4">
                                <img src={recycle} className="bg-transparent w-75" alt="" />
                            </div>
                            <div>
                                <h3 className="font-45 fw-700 fw-500 dark-color text-start text-white">
                                    {recyclableContainers !== null ? recyclableContainers : 'Loading...'}
                                </h3>
                                <p className="dark-color mb-2 text-white">Recyclable Containers</p>
                                <div className="btnnn_outlinee w-100 mx-0 text-center align-items-center mb-3 mb-md-0">
                                    <Link className="btnn-white-primaryy fs-18 fw-700" to={"/about"}>
                                        Read About Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="col d-flex align-items-start px-0 mx-0 primary-bgcolor py-3 px-3">
                            <div className="icon-square primary-bgcolor d-inline-flex align-items-center py-3 px-2 justify-content-center flex-shrink-0 me-4">
                                <img src={person} className="bg-transparent w-75" alt="" />
                            </div>
                            <div>
                                <h3 className="font-45 fw-700 fw-500 dark-color text-start text-white">
                                    {totalUsers !== null ? totalUsers : 'Loading...'}
                                </h3>
                                <p className="dark-color mb-2 text-white">Satisfied & Happy People</p>
                                <div className="btnnn_outlinee w-100 mx-0 text-center align-items-center mb-3 mb-md-0">
                                    <Link className="text-dark btnn-white-primaryy fs-18 fw-700" to={"/services"}>
                                        View Our Services
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="givemoney" className="container-fluid mx-0 px-0 m-auto">
                <div className="flowerlight_bg" style={flowerStyle}>
                    <div className="about-section padding-100 m-auto">
                        <div className="row pt-md-5">
                            <div className="col-12 col-md-6 mb-4 mb-md-0 mx-2 mx-md-0">
                                <div className="about_banner">
                                    <img src={cofounder} className="w-50" alt="" />
                                    <h1 className="font-45 fw-500 text-start text-white mb-0 mt-1">Abdul Samadi</h1>
                                    <h2 className="secondary_heading mb-0 font-22 text-white fw-400">C0 - Founder</h2>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mx-2 mx-md-0 d-flex flex-column justify-content-center">
                                <div className="about_text">
                                    <h2 className="secondary_heading mb-0 dark-color text-white font-22 fw-600">Why Choose Us?</h2>
                                    <h1 className="font-45 fw-700 text-start text-white dark-color mb-3">We Make Sure your Recyclable Items Go to the Right Place</h1>
                                </div>
                                <div className="about_description">
                                    <p className="dark-color mt-3 mb-4 pe-3 pe-md-0 text-white">
                                        Shred the Bottle is an Alberta-licensed recycling business that allows customers
                                        to claim the amount paid against for the refundable items without leaving their
                                        homes or offices. It was prompted by the idea that many people do not recycle owing to
                                        the difficulties of sorting and dropping off.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Whyus;