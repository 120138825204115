import React from 'react'
import herobanner from "../../assets/images/25712422_Mesa de trabajo 1 1.png";
import {Link } from 'react-router-dom';

function Hero() {
  return (
    <section id="hero">
    <div className="container py-4 py-md-0">
        <div className="row">
            <div className="col-12">
                <div className="banner_img w-100">
                    <img src={herobanner} className="w-100 h-100 object-fit-contain" alt=""/>
                </div>
            </div>
            <div className="col-12">
                <div className="container text-center pt-1 ">
                    <div className="position-relative mx-auto my-3 hero_content" >
                        <h1 className="font-45 fw-500 position-relative zindex-2 mb-0">Reliable & Affordable
                            Recycling Services</h1>

                    </div>
                    <p className=" pb-3 mb-2 mb-sm-3 mb-lg-4 mx-auto text-center  hero_content">
                        Empowering Positive Change by Recycling Plastic Bottles Together, Towards a Cleaner
                        Environment and Brighter Future.</p>
                    <div
                        className="d-flex flex-column m-auto align-items-center flex-md-row justify-content-center">
                        <div className="btnn w-100 mx-2 mb-3 mb-md-0"><a className=" btn-primaryy fs-18 fw-700 "
                                href='https://shredbottle.iotaiy.com/register'>Sign Up</a></div>
                        <div className="btnn_outline w-100 mx-2 mb-3 mb-md-0"><a
                                className=" btn-outline-primaryy fs-18 fw-700 " href="https://shredbottle.iotaiy.com/customer/create/pickup-request">Request
                                Pickup</a></div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Hero